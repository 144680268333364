import { NgModule } from '@angular/core';
import { RoutesEnum } from '../ts/enums/routes.enum';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../components/auth/services/auth-guard.service';
import { MainAuthGuardService } from '../components/auth/services/main-auth-guard.service';
import { UserSettingsGuardService } from './../services/guards/user-settings-guard.service';
import { NotFoundComponent } from '../standalone/components/ui/not-found/not-found.component';
import { PermissionsSectionKey } from '../components/auth/ts/enums/permissions-section-key.enum';
import { FeaturePermissionsName } from '../components/core/ts/enums/feature-permission-name.enum';
import { ToolsPreviewComponent } from '../components/core/components/core-sidebar-items/tools-preview/tools-preview.component';
import { NetworkPreviewComponent } from '../components/core/components/core-sidebar-items/network-preview/network-preview.component';
import { PhoneTabPreviewComponent } from '../components/core/components/core-sidebar-items/phone-tab-preview/phone-tab-preview.component';
import { DnsWhiteListFeatureModule } from '../components/ADMIN_FEATURES/system-general-parameters/modules/dns-white-list.module';

import {
	AdminTabPreviewComponent
} from '../components/core/components/core-sidebar-items/admin-tab-preview/admin-tab-preview.component';
import {
	AutomationPreviewComponent
} from '../components/core/components/core-sidebar-items/automation-preview/automation-preview.component';

const routes: Routes = [
	{
		path: RoutesEnum.PHONE, component: PhoneTabPreviewComponent,
		data: { url: PermissionsSectionKey.PHONE }, canActivate: [MainAuthGuardService], children: [
			{
				path: RoutesEnum.OPC_PANEL, loadChildren: () =>
					import('../components/opc-panel/modules/opc-panel-feature.module')
						.then(m => m.OpcPanelFeatureModule), canLoad: [AuthGuardService], outlet: RoutesEnum.OPC_PANEL,
				data: { url: FeaturePermissionsName.OPC_PANEL, outlets: 'opc-panel' }
			},
			{
				path: RoutesEnum.PREFERENCES, loadChildren: () => import('../components/preferences/modules/preferences-feature.module')
					.then(m => m.PreferencesFeatureModule), canLoad: [AuthGuardService], outlet: RoutesEnum.PREFERENCES,
				data: { url: FeaturePermissionsName.PREFERENCES, outlets: 'preferences' }
			},
			{
				path: RoutesEnum.ADDRESSBOOK,
				loadChildren: () => import('../components/PHONE_FEATURES/address-book/modules/address-book-feature.module')
					.then(m => m.AddressBookFeatureModule),
				canLoad: [AuthGuardService], outlet: RoutesEnum.ADDRESSBOOK,
				data: { url: FeaturePermissionsName.ADDRESSBOOK, outlets: 'address-book' }
			},
			{
				path: RoutesEnum.RECENT_CALLS, loadChildren: () =>
					import('../components/PHONE_FEATURES/recent-calls/modules/recent-calls-feature.module')
						.then(m => m.RecentCallFeatureModule),
				canLoad: [AuthGuardService], outlet: RoutesEnum.RECENT_CALLS,
				data: { url: FeaturePermissionsName.RECENT_CALLS, outlets: 'recent-calls', }
			},
			{
				path: RoutesEnum.PHONE_TRAFFIC, loadChildren: () =>
					import('../components/phone-traffic/modules/phone-traffic-feature.module').then(m => m.PhoneTrafficFeatureModule),
				canLoad: [AuthGuardService], outlet: RoutesEnum.PHONE_TRAFFIC,
				data: { url: FeaturePermissionsName.STATISTICS, outlets: 'phone-traffic' }
			},
			{
				path: RoutesEnum.EXTENSIONS,
				loadChildren: () => import('../components/extensions/modules/extensions-feature.module')
					.then(m => m.ExtensionsFeatureModule), data: { outlets: 'extensions' },
				canLoad: [AuthGuardService], outlet: RoutesEnum.EXTENSIONS
			},
			{
				path: RoutesEnum.ROUTINGS,
				loadChildren: () => import('../components/routings/modules/routings-feature.module').then(m => m.RoutingsFeatureModule),
				canLoad: [AuthGuardService], outlet: RoutesEnum.ROUTINGS,
				data: { url: FeaturePermissionsName.ROUTINGS, outlets: 'routings' }
			}
		]
	},
	{
		path: RoutesEnum.NETWORK, component: NetworkPreviewComponent,
		data: { url: PermissionsSectionKey.NETWORK }, canActivate: [MainAuthGuardService], children: [
			{
				path: RoutesEnum.LAN_MONITOR, loadChildren: () =>
					import('../components/lan-monitor/modules/lan-monitor-feature.module').then(m => m.LanMonitorFeatureModule),
				canLoad: [AuthGuardService], outlet: RoutesEnum.LAN_MONITOR
			},
			{
				path: RoutesEnum.NETWORK_INFO, loadChildren: () =>
					import('../components/networking-info/modules/networking-info-feature.module').then(m => m.NetworkInfoFeatureModule),
				canLoad: [AuthGuardService], outlet: RoutesEnum.NETWORK_INFO, data: { outlets: 'network-info' }
			},
			{
				path: RoutesEnum.NETWORK_SETTINGS, loadChildren: () =>
					import('../components/networking/modules/networking-feature.module').then(m => m.NetworkingFeatureModule),
				canLoad: [AuthGuardService], outlet: RoutesEnum.NETWORK_SETTINGS, data: { outlets: 'network-settings' }
			},
			{
				path: RoutesEnum.NETWORK_ADMIN_FEATURES, loadChildren: () =>
					import('../components/networking/modules/networking-admin-features.module').then(m => m.NetworkingAdminFeaturesModule),
				canLoad: [AuthGuardService], outlet: RoutesEnum.NETWORK_ADMIN_FEATURES, data: { outlets: 'network-admin-features' }
			},
		]
	},
	{
		path: RoutesEnum.AUTOMATION, component: AutomationPreviewComponent,
		data: { url: PermissionsSectionKey.AUTO }, canActivate: [MainAuthGuardService], children: [
			{
				path: RoutesEnum.RIO_RVS, loadChildren: () => import('../components/rio-rvs/modules/rio-rvs-feature.module')
					.then(m => m.RioRvsFeatureModule), canLoad: [AuthGuardService], outlet: RoutesEnum.RIO_RVS,
				data: { url: FeaturePermissionsName.DISCOVERY, outlets: 'rio-rvs' }
			},
			{
				path: RoutesEnum.PORTS_CONFIGURATION, loadChildren: () => import('../components/rio-rvs/modules/rio-rvs-feature.module')
					.then(m => m.RioRvsFeatureModule), canLoad: [AuthGuardService], outlet: RoutesEnum.PORTS_CONFIGURATION,
				data: { url: FeaturePermissionsName.DISCOVERY }
			},
			{
				path: RoutesEnum.AUTOMATION_MAPS,
				loadChildren: () => import('../components/automation/modules/automation-feature.module')
					.then(m => m.AutomationFeatureModule), canLoad: [AuthGuardService], outlet: RoutesEnum.AUTOMATION_MAPS,
				data: { url: FeaturePermissionsName.MAPS, outlets: 'automation-maps' }
			},
			{
				path: RoutesEnum.AUTOMATION_LOG, loadChildren: () =>
					import('../components/AUTO_FEATURES/automation-log/modules/automation-log-feature.module')
						.then(m => m.AutomationLogFeatureModule), canLoad: [AuthGuardService], outlet: RoutesEnum.AUTOMATION_LOG,
				data: { url: FeaturePermissionsName.LOGS, outlets: 'automation-log' }
			},
			{
				path: RoutesEnum.AUTOMATION_PROGRAM, loadChildren: () =>
					import('../components/automation-program/modules/automation-program-feature.module')
						.then(m => m.AutomationProgramFeatureModule), canLoad: [AuthGuardService], outlet: RoutesEnum.AUTOMATION_PROGRAM,
				data: { url: FeaturePermissionsName.PROGRAMS, outlets: 'automation-program' }
			},
			{
				path: RoutesEnum.AUTOMATION_PROGRAMS_EDIT, loadChildren: () =>
					import('../components/automation-program-edit/modules/automation-program-edit-feature.module')
						.then(m => m.AutomationProgramEditFeatureModule),
				canLoad: [AuthGuardService], outlet: RoutesEnum.AUTOMATION_PROGRAMS_EDIT,
				data: { url: FeaturePermissionsName.SHEETS, outlets: 'table-programs-edit' }
			}
		]
	},
	{
		path: RoutesEnum.TOOLS, component: ToolsPreviewComponent,
		data: { url: PermissionsSectionKey.TOOLS }, canActivate: [MainAuthGuardService], children: [
			{
				path: RoutesEnum.BASIC_LIST,
				loadChildren: () => import(
					'../components/TOOLS_FEATURES/basic-lists/modules/basic-list-feature.module')
					.then(m => m.BasicListModule), canLoad: [AuthGuardService], outlet: RoutesEnum.BASIC_LIST,
				data: { url: FeaturePermissionsName.LISTS, outlets: 'basic-list' }
			},
			{
				path: RoutesEnum.AUDIO, loadChildren: () => import('../components/audio-editor/modules/audio-feature.module')
					.then(m => m.AudioFeatureModule), canLoad: [AuthGuardService], outlet: RoutesEnum.AUDIO,
				data: { url: FeaturePermissionsName.AUDIO, outlets: 'audio' }
			},
			{
				path: RoutesEnum.ALERTS, loadChildren: () => import('../components/TOOLS_FEATURES/alerts/modules/alerts-feature.module')
					.then(m => m.AlertsFeatureModule), canLoad: [AuthGuardService], outlet: RoutesEnum.ALERTS,
				data: { url: FeaturePermissionsName.ALERT, outlets: 'alerts' }
			},
			{
				path: RoutesEnum.SMS, loadChildren: () => import('../components/TOOLS_FEATURES/sms/modules/sms-feature.module')
					.then(m => m.SmsFeatureModule), canLoad: [AuthGuardService], outlet: RoutesEnum.SMS,
				data: { url: FeaturePermissionsName.SMS, outlets: 'sms' }
			},
			{
				path: RoutesEnum.CP,
				loadChildren: () => import('../components/TOOLS_FEATURES/control-port/modules/control-port-feature.module')
					.then(m => m.ControlPortFeatureModule), canLoad: [AuthGuardService], outlet: RoutesEnum.CP,
				data: { url: FeaturePermissionsName.CONTROL_PORT, outlets: 'cp' }
			},
			{
				path: RoutesEnum.DISA, loadChildren: () => import('../components/TOOLS_FEATURES/disa/modules/disa-feature.module')
					.then(m => m.DisaFeatureModule), outlet: RoutesEnum.DISA,
				data: { url: FeaturePermissionsName.DISA, outlets: 'disa' }
			},
			{
				path: RoutesEnum.DISK,
				loadChildren: () => import('../components/TOOLS_FEATURES/disk-analyzer/modules/disk-analyzer-feature.module')
					.then(m => m.DiskAnalyzerFeatureModule), outlet: RoutesEnum.DISK,
				data: { url: FeaturePermissionsName.DISA, outlets: 'disk-analyzer' }
			}
		]
	},
	{
		path: RoutesEnum.ADMINISTRATION, component: AdminTabPreviewComponent,
		data: { url: PermissionsSectionKey.ADMIN }, canActivate: [MainAuthGuardService], children: [
			{
				path: RoutesEnum.USERS_MANAGEMENT,
				loadChildren: () => import('../components/user-preferences/modules/user-preferences-feature.module')
					.then(m => m.UserPreferencesFeatureModule), data: { outlets: 'admin-preferences' },
				canLoad: [AuthGuardService], outlet: RoutesEnum.USERS_MANAGEMENT
			},
			{
				path: RoutesEnum.USERS_RIGHTS,
				loadChildren: () => import('../components/user-rights/modules/user-rights-feature.module')
					.then(m => m.UserRightsFeatureModule), data: { outlets: 'users-rights' },
				canLoad: [AuthGuardService], outlet: RoutesEnum.USERS_RIGHTS
			},
			{
				path: RoutesEnum.VIRTUAL_OFFICE,
				loadChildren: () => import('../components/virtual-office/modules/virtual-office-feature.module')
					.then(m => m.VirtualOfficeFeatureModule), data: { outlets: 'virtual-office' },
				canLoad: [AuthGuardService], outlet: RoutesEnum.VIRTUAL_OFFICE
			},
			{
				path: RoutesEnum.ALL_RECENT_CALLS,
				loadChildren: () => import('../components/PHONE_FEATURES/recent-calls/modules/recent-calls-feature.module')
					.then(m => m.RecentCallFeatureModule), data: { outlets: 'all-recent-calls' },
				canLoad: [AuthGuardService], outlet: RoutesEnum.ALL_RECENT_CALLS
			},
			{
				path: RoutesEnum.SYSTEM_PARAMETERS,
				loadChildren: () =>
					import('../components/ADMIN_FEATURES/system-general-parameters/modules/system-general-parameters.module')
						.then(m => m.SystemGeneralParametersFeatureModule), data: { outlets: 'sys-parameters' },
				canLoad: [AuthGuardService], outlet: RoutesEnum.SYSTEM_PARAMETERS
			},
			{
				path: RoutesEnum.WHITELIST,
				loadChildren: () =>
					import ('../components/ADMIN_FEATURES/system-general-parameters/modules/dns-white-list.module')
						.then(m => m.DnsWhiteListFeatureModule), data: {	outlets: 'dns-whitelist' },
				canLoad: [AuthGuardService], outlet: RoutesEnum.WHITELIST
			},
			{
				path: RoutesEnum.BLACKLIST,
				loadChildren: () =>
					import ('../components/ADMIN_FEATURES/system-general-parameters/modules/dns-black-list.module')
						.then(m => m.DnsBlackListFeatureModule), data: {	outlets: 'dns-blacklist' },
				canLoad: [AuthGuardService], outlet: RoutesEnum.BLACKLIST
			},
			{
				path: RoutesEnum.NETWORK_ADMIN_FEATURES, loadChildren: () =>
					import('../components/networking/modules/networking-admin-features.module').then(m => m.NetworkingAdminFeaturesModule),
				canLoad: [AuthGuardService], outlet: RoutesEnum.NETWORK_ADMIN_FEATURES, data: { outlets: 'network-admin-features' }
			},
		]
	},
	{
		path: RoutesEnum.VIDEORECORDER, loadChildren: () => import('../components/videorecorder/modules/videorecorder-feature.module')
			.then(m => m.VideorecorderFeatureModule), canActivate: [MainAuthGuardService], data: { url: PermissionsSectionKey.VIDEO }
	},
	{
		path: RoutesEnum.USER_SETTINGS,
		loadChildren: () => import('../components/OTHER_FEATURES/user-settings/modules/user-settings-feature.module')
			.then(m => m.UserSettingsFeatureModule), canLoad: [UserSettingsGuardService]
	},
	{
		path: RoutesEnum.LOGIN,
		loadChildren: () => import('../components/auth/modules/login-feature.module').then(m => m.LoginFeatureModule)
	},
	{ path: RoutesEnum.NOT_FOUND, component: NotFoundComponent },
	{ path: RoutesEnum.MISMATCH, redirectTo: `/${RoutesEnum.NOT_FOUND}` }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { appInjector } from '../../app.component';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IAdminListItem } from '../../ts/models/admin-list-item.model';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { TranslateFixPipe } from '../../standalone/pipes/translate-fix.pipe';
import { IAdminListResponse } from '../../ts/models/admin-list-response.model';
import { NestedTranslationPipe } from '../../standalone/pipes/nested-translation.pipe';
import { ConfirmDialogComponent } from '../../components/ui-components/confirm-dialog/confirm-dialog.component';
import { GenericFormValue } from '../../components/ui-components/generic-table/ts/types/generic-form-value.type';
import * as customOperators from '../../custom-operators/custom-operators';

@Injectable({
	providedIn: 'root',
})
export class GlobalService {

	private readonly googleMapsAPIKey = 'AIzaSyCpEk9MwIHDHtHV9xr6GoXm-dCsI1A3KkE';
	private readonly adminListUrl = environment.abilisUrl + '/sys/admin/lists/get.json?type=';

	public modalRef: BsModalRef;

	private windowSizeEvent = new BehaviorSubject<number>(window.innerWidth);

	constructor(
		private http: HttpClient,
		private translate: TranslateService,
		private modalService: BsModalService
	) {}

	emitWindowSize(size: number) {
		this.windowSizeEvent.next(size);
	}

	getWindowSize$(): Observable<number> {
		return this.windowSizeEvent.asObservable();
	}

	fetchAdminList$(listTypes: string): Observable<IAdminListItem[]> {
		return this.http.get<IAdminListResponse>(this.adminListUrl + listTypes)
			.pipe(
				map(response => response.Response.Lists || []),
				customOperators.retryFromError$(1000)
			);
	}

	fetchGoogleMapsApi$(): Observable<boolean> {
		return this.http.jsonp(`https://maps.googleapis.com/maps/api/js?key=${this.googleMapsAPIKey}`, 'callback').pipe(
			map(() => true),
			catchError(() => of(false))
		);
	}

	getLanguageChangeState$(): Observable<LangChangeEvent> {
		return this.translate.onLangChange.asObservable();
	}

	translateCurlyBracesTranslation(resolvedValue: GenericFormValue, translateValue: boolean | undefined): string {
		return !translateValue ? `${resolvedValue}` : this.translateStringParts(resolvedValue);
	}

	translateStringParts(resolvedValue: GenericFormValue): string {
		return resolvedValue
			.toString()
			.split(/({{[^}]+}}+)/g)
			.map(partText => {
				if (partText.startsWith('{{') && partText.endsWith('}}')) {
					const translationPart = partText.slice(2, -2);

					return this.translate.instant(translationPart);
				}

				return partText;
			})
			.join('');
	}

	confirm(message: string, okCallback: () => void, cancelCallback?: () => void): void {
		const initialState = { okCallback, cancelCallback, message };

		this.modalRef = this.modalService.show(ConfirmDialogComponent, { initialState, backdrop: 'static' });
	}

	static translateUIValue(value: string): string {
		if (!value) { return ''; }

		const fixedTranslate = new TranslateFixPipe().transform(appInjector.get(TranslateService).instant(value), value);

		return new NestedTranslationPipe(appInjector.get(GlobalService)).transform(fixedTranslate, true, null);
	}
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { RioRvsDoutMode } from '../../ts/enums/rio-rvs-dout-mode.enum';
import { RioRvsDoutStart } from '../../ts/enums/rio-rvs-dout-start.enum';
import { RioRvsDoutControlState } from '../../ts/enums/rio-rvs-dout-control-state.enum';
import { AutomationDeviceShortPipe } from '../../../../pipes/automation-device-short.pipe';
import { createTrimWhitespaceValidator } from '../../../../utils/form-validators.validator';
import { FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { GenericTableBuilder } from '../../../networking/common/classes/table-builder.class';
import { IGenericTableRow } from '../../../ui-components/generic-table/ts/models/generic-table-row';
import { IGenericTableHeader } from '../../../ui-components/generic-table/ts/models/generic-table-header';
import { IGenericUnsetSelectedRow } from '../../../ui-components/generic-table/ts/models/generic-unset-selected-row.model';
import { IRioRvsModalDigitalLineDevice } from '../../ts/models/modal-line-devices/rio-rvs-modal-digital-line-device.model';

@Injectable({
	providedIn: 'root',
})
export class RioRvsDoutTableService extends GenericTableBuilder {

	private unsetSelectedRowState = new BehaviorSubject({ unsetSelectedRow: false });

	constructor(
		private formBuilder: FormBuilder,
		private translate: TranslateService
	) {
		super();
	}

	private readonly headerCellStyle = 'display: flex; justify-content: center; padding-left: 8px !important';

	buildTableHeaders(): IGenericTableHeader[] {
		return [
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.DOUT.hdrPort' },
				sort: { useSort: false },
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.DOUT.hdrMapIcon', headerStyle: this.headerCellStyle },
				sort: { useSort: false },
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.DOUT.hdrDesc' },
				sort: { useSort: false },
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.DOUT.hdrSigType' },
				sort: { useSort: false },
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.DOUT.hdrPulseDur' },
				sort: { useSort: false },
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.DOUT.hdrPulseGap' },
				sort: { useSort: false },
			},
			{
				header: {
					headerName: 'AUTO.PREVIEW.ADV.DOUT.hdrStartingState'
				},
				sort: { useSort: false },
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.DOUT.hdrDefaultState' },
				sort: { useSort: false },
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.DOUT.associatedDi' },
				sort: { useSort: false },
			},
			{
				header: { headerName: 'AUTO.PREVIEW.ADV.DOUT.associatedDiState' },
				sort: { useSort: false },
			},
			{
				header: { headerName: '', isAction: true },
				sort: { useSort: false },
			},
		];
	}

	buildTableRows(): IGenericTableRow[] {
		return [
			{
				cell: { cellName: 'lineDeviceType', visibleCellCondition: true, pipe: new AutomationDeviceShortPipe() },
				edit: { editMode: false },
			},
			{
				cell: {
					cellName: 'imagePath', visibleCellCondition: true, isImage: true,
					customImageHeight: '22px', hideImagePath: true
				},
				edit: { editMode: false },
			},
			{
				cell: { cellName: 'description', visibleCellCondition: true },
				edit: { editMode: false },
			},
			{
				cell: { cellName: 'mode', visibleCellCondition: true, sentenceCase: true },
				edit: { editMode: false },
			},
			{
				cell: {
					cellName: 'pulse-t',
					visibleCellCondition: 'mode---EQUAL---PULSE',
				},
				edit: { editMode: false },
			},
			{
				cell: {
					cellName: 'pulse-gap',
					visibleCellCondition: 'mode---EQUAL---PULSE',
				},
				edit: { editMode: false },
			},
			{
				cell: {
					cellName: 'startTranslated',
					visibleCellCondition: true,
					sentenceCase: true,
				},
				edit: { editMode: false },
			},
			{
				cell: { cellName: 'dft', visibleCellCondition: true, sentenceCase: true },
				edit: { editMode: false },
			},
			{
				cell: { cellName: 'ctrlDiUI', visibleCellCondition: true, sentenceCase: true, translateValue: true },
				edit: { editMode: false },
			},
			{
				cell: { cellName: 'ctrlDiStUI', visibleCellCondition: true, sentenceCase: true, translateValue: true },
				edit: { editMode: false },
			},
			{
				cell: { cellName: '', visibleCellCondition: true, isAction: true },
				edit: { editMode: false },
			},
		];
	}

	buildForm(): FormArray {
		return this.formBuilder.array([]);
	}

	setUnselectedRowState(rowState: boolean): void {
		this.unsetSelectedRowState.next({ unsetSelectedRow: rowState });
	}

	getUnsetSelectedRowState$(): Observable<IGenericUnsetSelectedRow> {
		return this.unsetSelectedRowState.asObservable();
	}

	formatDoutDevices(digitalOutputDevices: IRioRvsModalDigitalLineDevice[]): IRioRvsModalDigitalLineDevice[] {
		return digitalOutputDevices.map(digitalOutputItem => {
			const { mode, start, ctrlDi, ctrlDiSt } = digitalOutputItem;
			const modeTranslated = this.translateModeValue(mode);
			const startTranslated = this.translateStartValue(start);
			const ctrlDiUI = this.translateNoneValue(ctrlDi);
			const ctrlDiStUI = this.translateNoneValue(ctrlDiSt);

			return { ...digitalOutputItem, modeTranslated, startTranslated, ctrlDiUI, ctrlDiStUI };
		});
	}

	translateNoneValue(value: string): string {
		return value === '#' ? '{{COMMON.none}}' : value;
	}

	translateModeValue(mode: RioRvsDoutMode): string {
		const modeValue = mode === RioRvsDoutMode.PULSE ? 'comboPulse' : 'comboConst';

		return this.translate.instant(`AUTO.PREVIEW.ADV.DOUT.${modeValue}`);
	}

	translateStartValue(startValue: RioRvsDoutStart): string {
		const startValuePath = startValue === RioRvsDoutStart.DFT ? 'comboDefault' : 'comboLastKnown';

		return this.translate.instant(`AUTO.PREVIEW.ADV.DOUT.${startValuePath}`);
	}

	buildDoutForm(): FormGroup {
		return this.formBuilder.group({
			mode: [''],
			start: [''],
			dft: [''],
			imagePath: [''],
			description: ['', [Validators.maxLength(14)]],
			pulseGap: ['', this.getPulseGapValidators()],
			pulseDuration: ['', this.getPulseDurationValidators()],
			associatedDi: [''],
			associatedDiState: [RioRvsDoutControlState.NONE],
			alert: [''],
			alertSt: ['']
		});
	}

	getPulseGapValidators(): ValidatorFn[] {
		return [
			createTrimWhitespaceValidator(),
			Validators.pattern(/^[+-]?([1-9]|[1-9]\d{1,3}|[1-5]\d{3,4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/)
		];
	}

	getPulseDurationValidators(): ValidatorFn[] {
		return [
			createTrimWhitespaceValidator(),
			Validators.pattern(/^[+-]?([1-9]|[1-9]\d{1,8}|[1-3]\d{9}|41\d{8}|428\d{7}|4293\d{6}|42948\d{5}|429495\d{4}|4294966\d{3}|42949671\d{2}|429496728\d{1}|429496729[0-5])$/)
		];
	}
}
